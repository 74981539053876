<template>
  <div class="banner grey lighten-5">
    <v-container>
      <v-row class="fill-height" align="center" justify="center" v-if="items">
        <v-col cols="12" md="3" v-for="item in items" :key="item.id">
          <router-link :to="{name: 'read', params:{id: item.slug}}">
          <v-card class="mx-auto">
            <v-card-text>
              <v-img height="200" :src="item.naskah.cover_url"></v-img>
              <p class="text-body-1 text--primary mt-3">
                {{ item.naskah.title }}
              </p>
            </v-card-text>
          </v-card>
          </router-link>
        </v-col>
      </v-row>
      <v-row class="fill-height" v-else>
        <v-col cols="12" md="3" v-for="i in 3" :key="i">
          <v-skeleton-loader
            class="p-3"
            type="card, text@3, button"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <div class="text-center mt-3">
        <v-pagination
          v-model="page"
          :length="limitPage"
          circle
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      items: [],
      loadingData: false,
      limitPage: 0,
      page: 1,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loadingData = true;

      axios
        .get("customer/library?page=" + this.page, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          this.items = response.data.data.data.data;
          this.limitPage = response.data.data.data.total;
          this.loadingData = true;
        })

        .catch((error) => {
          console.log(error);
          this.submitForm = false;
        });
    },
  },
};
</script>
<style>
a{
  text-decoration: none !important;
}
</style>