<template>
  <div>
    <div class="text-h6 text-uppercase font-weight-black mb-5">My Library</div>
    <Library />
  </div>
</template>

<script>
import Library from "@/components/Account/Library.vue";
export default {
  components: {
    Library,
  },
};
</script>
